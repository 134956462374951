import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { NamedLink } from '../../components';
import css from './OrderBreakdown.module.css';

const LineItemCreatorPlusFreeHourMaybe = props => {
  const { lineItems, intl, currentUser, listing } = props;
  
  // Check if the studio is featured
  const isFeaturedStudio = listing?.attributes?.publicData?.featuredStudio === 'yes';

  // If not a featured studio, don't show any Creator+ related messages
  if (!isFeaturedStudio) {
    return null;
  }

  // If not signed in, show the signup promo
  if (!currentUser) {
    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <a 
            href="/p/stuvi-creator"
            className={css.creatorPlusPromo}
          >
            Get a free hour with Stuvi Creator+
          </a>
        </span>
      </div>
    );
  }

  const metadata = currentUser?.attributes?.profile?.metadata;
  const hasActiveSubscription = metadata?.subscriptionStatus === 'active';
  const hasStripeCustomer = !!metadata?.stripeCustomerId;
  const freeHoursUsedThisMonth = metadata?.freeHoursUsedThisMonth || 0;
  const pendingFreeHours = metadata?.pendingFreeHours || 0;
  const totalFreeHours = freeHoursUsedThisMonth + pendingFreeHours;

  // Calculate how many hours are left to get the free hour
  const bookingHours = lineItems
    .filter(item => item.code === 'line-item/hour' && !item.reversal)
    .reduce((sum, item) => sum + item.quantity, 0);

  const hoursToFreeHour = 3 - bookingHours;

  // No subscription or stripe customer - show signup promo
  if (!hasActiveSubscription || !hasStripeCustomer) {
    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <a 
            href="/p/stuvi-creator"
            className={css.creatorPlusPromo}
          >
            Get a free hour with Stuvi Creator+
          </a>
        </span>
      </div>
    );
  }

  // Si el usuario ya usó todas sus horas gratis este mes
  if (totalFreeHours >= 3) {
    return (
      <div className={css.lineItem}>
        <span className={css.creatorPlusProgress}>
          You've used all your free hours this month
        </span>
      </div>
    );
  }

  // Has subscription but hasn't booked enough hours yet
  if (hoursToFreeHour > 0) {
    return (
      <div className={css.lineItem}>
        <span className={css.creatorPlusProgress}>
        {hoursToFreeHour} more hour{hoursToFreeHour > 1 ? 's' : ''} to get your free Creator+ hour
        </span>
      </div>
    );
  }

  // Show the free hour discount if it's being applied
  const creatorPlusItem = lineItems.find(
    item => item.code === 'line-item/creator+-free-hour' && !item.reversal
  );

  if (creatorPlusItem) {
    return (
      <div className={css.lineItem} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span className={css.creatorPlusLabel}>
          Creator+ free hour
        </span>
        <span className={css.creatorPlusValue}>{formatMoney(intl, creatorPlusItem.lineTotal)}</span>
      </div>
    );
  }

  return null;
};

export default LineItemCreatorPlusFreeHourMaybe; 